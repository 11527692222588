import React from 'react'
import Body from '../components/Body'
import Footer from '../components/Footer'
import Header from '../components/Header'

export default function Home() {
    return (
        <div>
            <Body />
        </div>
    )
}
