import React from 'react'
import { Link } from 'react-router-dom'
import data from '../config'
import '../App.css'

export default function Header() {
    return (
        <div className='bg-light'>
            <div className="container py-4">
                <Link className='nav-link' to={'/'}>
                    <h4 className=''>  <span className='bg-danger text-white header-logo-text'>{data.logoPart1}</span><span className='bg-muted text-danger header-logo-text' > {data.logoPart2} </span><span className='text-muted'></span> </h4>
                </Link>
                <div className='col-md-10 col-lg-8 mt-3 col-12'>{data.logoSubtitle}</div>
            </div>
        </div>
    )
}
