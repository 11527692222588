import React from 'react';
import Unsubscribe from '../pages/unsubscribe';

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className='bg-dark text-white py-3'>
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div>{currentYear} © All Rights Reserved</div>
                    <Unsubscribe />
                </div>
            </div>
        </footer>
    );
}
