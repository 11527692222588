import React, { useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import dataConfig from '../config'

export default function Unsubscribe() {
    
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const form = useRef();
    const onSubmit = data => {

        emailjs.sendForm(`${dataConfig.REACT_APP_EMAIL_SERVICE_ID}`, `${dataConfig.REACT_APP_EMAIL_UNSUBSCIBE_TEMPLATE}`, form.current, `${dataConfig.REACT_APP_EMAIL_PUBLIC_KEY}`)
            .then((result) => {
                console.log(result);
                setError(false);
                setSuccess(true);
                reset()
            }, (error) => {
                console.log(error);
                setError(true);
                setSuccess(false)
                reset()
            });

    };

    return (
        <div className="col-md-4">
                    <h5>Unsubscribe</h5>
                    <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                        <div className="position-absolute invisible">
                            <label htmlFor="domain" className="form-label">Domain*</label>
                            <input id="domain" className='form-control' value={dataConfig.fullname} {...register("domain", { required: true })} />
                        </div>
                        <div className="mb-3 d-flex align-items-center">
                            <input id="email" className="form-control me-2" placeholder="Email (required)" {...register("email", { required: true })} />
                            <input className="btn btn-info" type="submit" value="Submit" />
                        </div>
                    </form>
                    {errors.email && <span className='text-danger ms-1'>Please Enter Email Id</span>}
                    {success && <div className="alert mt-3 alert-success alert-dismissible fade show" role="alert">
                        <strong>Success </strong> You've been unsubscribed from our mailing list.
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>}
                    {error && <div className="alert mt-3 alert-danger alert-dismissible fade show" role="alert">
                        <strong>Sorry! </strong> something went wrong please try again latter.
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>}
        </div>
    )
}
